//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
export default {
  components :{
    ValidationObserver
  },
  layout: 'auth',
  auth: 'guest',
  data () {
    return {
      request: {
        email: '',
        password: ''
      },
      error: null
    }
  },
  methods: {
    async login() {
      this.error = null;
      try {
        const req = await this.$auth.loginWith("local", {
          data: {
            identifier: this.request.email,
            password: this.request.password,
          },
        });
          if (this.$route.query.package !== undefined) {
            this.$router.push({
              path: '/create-invitation',
              query: {
                package: this.$route.query.package
              }
            })
          }else{
            this.$router.push('/')
          }
          console.log(req)
      } catch (e) {
        alert(e.response.data.error.message)
      }
    },
    onSubmitForm () {
      this.$refs.loginForm.validate()
      .then(success => {
        if (!success) {
          // Observer errors is an object, not an array.
          const firstFieldError = Object.values(this.$refs.loginForm.fields).find(f => f.valid === false)
          if (firstFieldError !== null && firstFieldError !== undefined) {
            this.$refs[firstFieldError.name].onFocus();
          }
          return false
        }

        this.login()
      })
    },
    register () {
        if (this.$route.query.package !== undefined) {
            this.$router.push({
              path: '/register',
              query: {
                package: this.$route.query.package
              }
            })
        } else {
            this.$router.push('/register')
        }
    },
    googleAuth () {
        window.location = `${this.$config.baseURL}/api/connect/google`
    },
  }
}

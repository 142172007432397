//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';
export default {
  name: 'InputPassword',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      requirsed: false,
      default: 'Password'
    },
    required: {
      type: Boolean,
      required: false
    },
    pattern: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: 'Masukan Password',
      required: false
    },
    rules: {
      type: String,
    },
    vid: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 100
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: false,
      focused: false,
      showPassword: false,
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    isShow() {
      return this.type === 'text'
    }
  },
  watch: {
    inputValue () {
      const regex = RegExp(this.pattern)
      if (regex.test(this.value) === true) {
        this.error = false
      } else {
        this.error = true
      }
    }
  },
  methods: {
    onFocus () {
      this.focused = true
      this.$refs.input.focus()
    },
    onBlur () {
      this.focused = false
    },
    onChange () {
      this.$emit('change', this.value)
    }
  }
}
